// color variants
@import 'themes-vars.module.scss';

@import 'blinking.scss';

body {
  font-family: 'Lato' !important;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

// ==============================|| CUSTOM STYLES ||============================== //

.css-i83wvs-MuiPaper-root-MuiCard-root {
  border-radius: 0.5em;
}

a.MuiButton-root,
button {
  font-weight: 600 !important;
}

.listItemText {
  span {
    color: #f5f5f5 !important;
  }
}

.listItemButton {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 0 5em 5em 0;
  div {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}

.listItemButtonMini {
  border-radius: 10em;
  padding-left: 0.8em;
  width: 3.2em;
}

.listItemButton.selected,
.listItemButtonMini.selected {
  background-color: $primaryMain;
}

.listItemButton:hover,
.listItemButtonMini:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.mainDrawer {
  border-right: 0;
  position: relative;
  z-index: 1202;

  // .MuiPaper-root {
  //   background-color: #003d6d !important;
  //   border-right: 0;
  // }
}

.navBtn {
  background: white;
  position: fixed;
  top: 20px;
  z-index: 1300;
  transition: all 0.2s ease-out;
  padding: 0.15em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.navBtn:hover {
  background: white;
}

table {
  table-layout: fixed;
  width: 100%;
}

td {
  overflow: hidden; /* optionnal*/
  text-overflow: ellipsis;
}

th {
  min-width: 100px;
}

.popper {
  z-index: 1500;
}

.MuiAvatar-img {
  width: 100%;
  height: auto;
}

.transparent-avatar {
  background: transparent;
}

.onhover-btn,
.onhover-chartview {
  transition: 0.2s all ease;
}

// .onhover-btn:hover {
//   background-color: rgb(33, 149, 243, 0.04);
// }

// .onhover-chartview:hover {
//   background-color: rgba(0, 0, 0, 0.04);
// }

.MuiInputBase-input {
  background-color: transparent;
}

.pointer {
  cursor: pointer;
}
