@mixin blinkBg($color1, $color2, $animation) {
  @keyframes #{$animation} {
    0% {
      background-color: $color1;
    }
    40% {
      background-color: $color2;
    }
    50% {
      background-color: $color2;
    }
    60% {
      background-color: $color2;
    }
    100% {
      background-color: $color1;
    }
  }
}

@mixin blink($animation) {
  @keyframes #{$animation} {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.blink {
  @include blink("blink");
  animation: blink 1.5s infinite;
}

.blinkcard-blue {
  @include blinkBg(transparent, rgb(253, 223, 171), "blinkingBlue");
  animation: blinkingBlue 1.5s infinite;
}

.blinkcard-yellow {
  @include blinkBg(transparent, #ffeb3b, "blinkingYellow");
  animation: blinkingYellow 1.5s infinite;
}
